//MainPage Logo Left
.sidebar-logo {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 10px;
  background-color: #ffffff;
  height: 100px !important;
}

.sidebar-fixed {
  z-index: 10000 !important;
}

.sidebar-brand {
  border-bottom: 0 solid white;
  background: transparent;
}

//Titles
.sub-title {
  font-weight: 700;
  font-size: 1rem;
  line-height: 29px;
  color: #1D1B1B;
}

.hidden {
  display: none;
}
.custom-date-input {
  padding: 8px 30px 8px 10px; /* Adjust padding as needed */
  border: 1px solid #ccc;
  background-position: right center;
  cursor: pointer; /* Change cursor to pointer on hover */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.custom-date-input::-webkit-calendar-picker-indicator {
  //display: none; /* Hide the default arrow button */
}

.small-text-gray {
  font-size: 0.9rem;
  color: grey;
}

.xs-gray-text {
  font-size: 12px;
  color: grey;
  line-height: normal;
}

.main-title_green {
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 29px;
  color: #1FAB89;
}

.stat_card {
  position: absolute;
  width: 365px;
  height: 94px;
  left: 247px;
  top: 162px;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

//BGs
.bg-blue {
  background: #008EBB;
}

.bg-green-light {
  background: #D7F5E7;
}

.bg-green {
  background: #1FAB89;
}

.bg-green-500 {
  background: #1FAB89;
}

.bg-green-200 {
  background: #ECFFF6;
}

.bg-green-700 {
  background: #E9FFF4;
}

.bg-red {
  background: #FF5C5C;
}
.bg-orange-500 {
  background: #F9956B;
}

.bg-yellow {
  background: #F7B100;
}

.bg-grey {
  background: #EAEAEA;
}

.bg-grey-100 {
  background: #F8F8F8;
}

.bg-grey-200 {
  background: #eaeaea;
}

.bg-grey-700 {
  background: #BABABA;
}
.text-grey-700 {
  color: #BABABA;
}
.text-grey-500 {
  color: #7A7474;
}

.bg-grey-900 {
  background: #6A6565
}

.bg-green-900 {
  background: #00A6B0;
}
.bg-green-800 {
  background: #00D9A5;
}
.bg-blue-200 {
  background: #00A6B0;
}

.bg-blue-500 {
  background: #0071B0;
}

.text-blue-500 {
  color: #0071b0;
}

.text-yellow {
  color: #eaa800;
}

.bg-blue-500:hover{
  background: #0071B0;
}
.bg-blue-500:focus {
  background: #0071B0;
}
.border-green-900 {
  border-bottom: solid 4px #00A6B0;
}

.border-red {
  border-bottom: solid 4px #FF5C5C;
}

.border-green-500 {
  border-bottom: solid 4px #1FAB89;
}

.border-yellow {
  border-bottom: solid 4px #F7B100;
}
.yellow-border {
  border: solid 1px #f7b100;
}
.yellow-border:hover {
  border: solid 1px #f7b100;
}


.placeholder-style option:first-child {
  color: red;
}

// TEXTS
.text-green {
  color: #1FAB89;
}

.text-yellow {
  color: #F7B100;
}
.text-yellow:hover {
  color: #f7b100;
}

.bg-yellow {
  background-color: #f7b100;
  outline-color: #f7b100;
}

.bg-yellow:hover {
  background-color: #f7b100;
  outline-color: #f7b100;
}

.text-red {
  color: #FF5C5C;
}

.text-1 {
  font-size: 36px;
}

.text-2 {
  font-size: 28px;
}

.text-3 {
  font-size: 20px;
}

.text-small {
  font-size: small;
  letter-spacing: 0.75px;
}

.text-wn-8 {
  font-size: 8px !important;
  font-weight: 400;
}

.text-wn-10 {
  font-size: 10px !important;
  font-weight: 700;
}

.text-wn-12 {
  font-size: 12px !important;
  font-weight: 700;
}

.text-wn-16 {
  font-size: 16px !important;
  font-weight: 700;
}

.text-wn-18 {
  font-size: 18px !important;
  font-weight: 700;
}

p {
  word-wrap: break-word;
}

.ql-container {
  /* Custom styles for the toolbar */
  min-height: 100px;
}

.input-warning {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e55353
}

.employer-logo {
  width: inherit;
  height: auto;
}

.table-border-grey {
  border-color: grey;
}

.table-border-black {
  border-color: black;
}

.reports-table {
  border: 1px solid #b1b7c1;
  border-radius: 5px;
}

//Images
.stat-icon {
  font-size: 2rem;
}

.header-card {
  height: 87px;
  padding: 5px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.circle {
  border-radius: 50%;
  width: 47px;
  height: 47px;
}

.squaredBg {
  width: 100px;
  height: 100px;
}

.small-square {
  width: 60px;
  height: 60px;
}

.container-border {
  box-sizing: border-box;
  height: auto;
  background: #FFFFFF;
  border: 1px solid #BABABA;
  border-radius: 7px;
}

.posting-group {
  margin: 10px 0 10px 10px;
}

//Buttons
.outline-btn {
  border: 2px solid var(--primary) !important;
  border-radius: 6px;
  padding: 5px;
  margin-right: 20px;
  background: #FFFFFF;
}

.filter-button {
  margin-right: 10px;
  color: var(--primary);
  font-size: 16px;
  font-weight: 700;
}

.squared-icon {
  width: 30px;
  height: 30px;
  background: #D7F5E7;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-card {
  padding: 10px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: #FFFFFF;
}

//User AdminDashboard Cards
.stat_card_bordered {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  border: 5px solid;
  border-image: linear-gradient(241.17deg, #00C566 15.35%, rgba(0, 197, 102, 0.18) 78.58%);
  border-radius: 11px;
}

.module-border-wrap {
  position: relative;
  background: linear-gradient(241.17deg, #00C566 15.35%, rgba(0, 197, 102, 0.18) 78.58%);
  padding: 1px;
}

.job-force {
  background: url("../images/find-job-bg.png") no-repeat;
  background-size: cover;
}

.job-card-header {
  max-width: 500px;
}

.training-card-header {
  max-width: 500px;
}

.training-force {
  background: #FDFBFB
}

.work-force-header-text-section {
  margin-left: 20%;
  max-width: 750px;
  width: 100%;
  margin-top: 100px;
}

.bold-header {
  color: #008EBB;
  font-size: 60px;
  line-height: 1.2em;
}

.bolder-header {
  color: #000;
  font-size: 70px;
  line-height: 1.2em;
}

.work-force-sub-header {
  color: #000;
  font-size: 20px;
  line-height: 1.2em;
}

//Menu
.menu-shadow {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.module {
  background: #FFFFFF;
  padding: 1rem;
}

.skill-set-cards {
  border: 1px solid #1FC776;
  border-radius: 6px;
}

//.skill-set{
//  background: #F2FFF7;
//  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.15);
//  border-radius: 6px;
//}

//Employers

.border-b-grey {
  border-bottom: 2px solid #BABABA;
}

.border-grey {
  border: 2px solid #BABABA;
}

.dotted-border {
  border: 2px dotted #BABABA;
}

// User Profile
.user-profile {
  p {
    font-size: 12px;
  }
}

.apply-job-btn {
  font-size: 0.85rem;
}

.user-profile-update {
  .form-control {
    background-color: var(--cui-input-bg, #fff) !important;
  }
}

.messages-count {
  font-size: 10px;
  padding: 0 2px;
}

.profile-avatar {
  border-radius: 100%;
  border: 1px solid grey;
  height: 32px;
  width: 32px;
  object-fit: cover;
}

.profile-pic-div {
  border-radius: 50%;
  height: 64px;
  width: 64px;
}

.profile-pic {
  object-fit: contain;
  border-radius: 50%;
  border: 1px solid grey;
  height: 100%;
  width: 100%;
  padding: 4px;
}

.job-card {
  .img-div {
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    margin: 0;
    padding: 0;
    line-height: normal;
  }

  h6 {
    margin: 0;
  }
}

.bg-opacity-95 {
  --cui-bg-opacity: 0.95;
}

p {
  margin-top: 0;
  margin-bottom: 0 !important;
}

.active-message-tab {
  color: var(--primary);
  border: none;
  border-bottom: 2px solid var(--primary);
}

.active-message {
  border-left: 5px solid var(--primary) !important;
  background-color: rgb(0, 197, 102, 0.1);
}

.message {
  border-left: 5px solid transparent;

  &:hover {
    background-color: rgba(0, 197, 102, 0.05);
  }
}

//NEW LOADER
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--primary);
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

.multi-step-line {
  width: 100%;
  position: relative;
}

.step-list {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  flex: 1;
}

.step {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}

.step.active {
  background-color: #1FAB89;
}

.step.passed {
  background-color: #1FAB89;
}

.line-connector {
  position: absolute;
  top: 50%;
  left: calc(100% + 1px);
  flex-grow: 1;
  height: 2px;
  background-color: #ccc;
  transform: translateY(-50%);
}

.testimonial-text {
  font-size: x-large;
}

.testimonial-pic {
  width: 54px !important;
  border-radius: 50%;
}

.text-caps {
  text-transform: uppercase;
}

.gray-paragraph {
  color: dimgray;
  text-align: left;
}

.sign-up-options {
  border-radius: 15px;
  margin: 10px;
  padding: 20px 30px;
  border: 1px solid grey;
  background: #FFFFFF;
  color: grey;
  cursor: pointer;
}

.sign-up-options-active,
.sign-up-options:hover {
  background: var(--primary);
  color: #FFFFFF;
  border: 1px solid var(--primary);
}

.custom-file-upload {
  cursor: pointer;
  border: 1px dotted gray;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
}

.custom-file-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.td-single-line {
  white-space: nowrap;
}
.table {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.applicant-table-row > .td-single-line {
  padding: 3px;
}

.cv-builder-editor {
  min-height: 100vh; /* Set the minimum height to 100% of the viewport height */
}

.skills_box {
  box-shadow: 0 1px 10px 0 #00000026;
  width: 120px;
  height: 120px;
  top: 189px;
  left: 324px;
  padding: 32px 41px 11px 42px;
  border-radius: 5px;
  gap: 10px;
}

#reports-table {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.assessment-icon {
  width: 50px;
  height: 50px;
}

#reports-table thead tr th {
  border-bottom: 0 !important;
}

#reports-table tr td:first-child {
  border-left: 2px solid #e8e8e8 !important;
}

#reports-table tr td:last-child {
  border-right: 2px solid #e8e8e8 !important;
}

#reports-table tr,
#reports-table td {
  cursor: pointer;
  border-top: 2px solid #e8e8e8 !important;
  border-bottom: 2px solid #e8e8e8 !important;
  padding: 5px 10px;
  background-color: #FFFFFF;
}

.smaller-text-12 {
  font-size: 12px;
}

.assessment-images {
  width: 100%;
  border: 1px solid #c1c1c1;
}

.assessment-stat-border {
  border-left: 2px solid #c1c1c1;
  border-right: 2px solid #c1c1c1;
}

.user-profile-card {
  border-radius: 10px;
  height: 150px;
  width: 150px;
  max-height: 100%;
  max-width: 100%;
}

.profile-card-pic {
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 1px 1px 5px #c1c1c1;
  height: 140px !important;
  width: 140px !important;
  max-height: 100%;
  max-width: 100%;
}

.profile-card-label {
  position: relative;
  top: -20px;
}

.profile-link-tab {
  font-size: 14px !important;
}

.profile-banner-pic {
  width: 100vw;
  max-width: 100%;
  height: 270px;

  .banner {
    width: 100%;
    height: 190px;
  }

  .picture {
    width: 128px;
    height: 128px;
    position: relative;
    top: -70px;
    left: 33px;
    border: 3px solid white;
    object-fit: cover;
  }

  .share-button {
    position: relative;
    top: -120px;
  }
}

.landing-page, .nav-header {
  background-image: linear-gradient(to right, #FCFEFD , #EDFFF6);
}

.landing-section-border {
  border-bottom: 1px solid #BABABA;
}

.green-check-icon {
  width: 30px;
}

.social-image {
  width: 28px;
}

.landing-page-statistics {
  border-image: linear-gradient(
      to left,
      rgb(255, 255, 255) 1%,
      rgb(0, 197, 102) 50%,
      rgb(255, 255, 255) 100%
    )
    100% 0 100% 0/2px 0 2px 0 stretch;
}

.testimonial-border {
  height: 65%;
  margin-right: 10px;
  border-right: 2px solid #fff;
}

.featured-job {
  height: fit-content;
}

#landing-talent-section {
  list-style-image: url("./../icons/talent-dot.svg");
  margin: 0;
  padding-left: 48px;
  position: relative;
}

#landing-talent-section:before {
  content: "";
  display: inline-block;
  width: 2px;
  background: var(--primary-dark);
  position: absolute;
  left: 3px;
  //top: 12px;
  height: 100%;
}

#landing-talent-section li {
  position: relative;
  padding-left: 0;
  margin-bottom: 15px;
}

.talents-point {
  position: relative;
  top: -50px;
}

.process-heading {
  cursor: pointer;
}

.lading-page-body {
  font-size: 0.85rem;
}

.lightGreen {
  background: #30d3ac;
}

.process-body {
  display: none;
  font-size: 0.85rem;
}

.process-div:hover .process-body {
  display: block;
}

.interview-tab {
  border-bottom: 2px solid lightgray;
  width: 100%;
  padding-bottom: 10px;
  cursor: pointer;
}

.interview-tabs .active {
  border-bottom: 4px solid var(--primary);
  color: var(--primary);
  font-weight: bold;
}

.applicant-stat-card {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.shadow-md {
  box-shadow: 0 0.125rem 0.95rem -3px rgba(0, 0, 21, 0.175) !important;
}
.shadow-b {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.table-dropdown + div {
  width: fit-content !important;
  //position: relative;
  //top: -45px;
  //left: 55px;

  & > p {
    line-height: normal;
  }
}

.sponsor-top-image {
  position: relative;
  bottom: -190px;
  left: -90px;
}

.sponsor-bottom-image {
  position: relative;
  top: -155px;
  right: -281px;
}

.kpi-banner {
  height: 180px;
  background-image: linear-gradient(180deg, #172153CC, #1FAB89CA);
}

.kpi-table-image { height: 42px; }

.kpi-table-email { line-height: normal; }

.add-new-kpi {
  background-color: #F3FFFC;
  border: 2px dashed var(--primary);
}

.trainer-actions {
  position: absolute;
  margin-left: 25px;
  margin-top: -45px;
}

.trainer-actions small { border-left: 3px solid transparent; }

.trainer-actions small:hover {
  background-color: #F6F6F6;
  border-radius: 3px;
  border-left: 3px solid var(--primary);
}

.posts-filter {
  position: absolute;
  margin-left: -150px;
  margin-top: 5px;
}

.larger-checkbox {
  transform: scale(1.5);
  margin: 0 10px;
}

.pipeline-candidate-pic {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
}

.pipeline-candidate-skills {
  font-size: 12px;
  margin-top: -2px;
  max-width: 470px;
}

.pipeline-candidate-state {
  font-size: 11px;
  margin-top: -2px;
}

@media screen and (min-width: 1024px) and (max-height: 1310px) {
  //#landing-talent-section:before {
  //  top: 12px;
  //  height: 100%;
  //}

  .talent-heading {
    margin-bottom: 5rem !important;
  }
}

@media (min-width: 768px) {
  .flex-md-column {
    flex-direction: column !important;
  }
  .landing-hero-section {
    min-height: 50vh !important;
  }

  .sponsor-top-image {
    position: relative;
    bottom: -108px;
    left: -50px;
    width: 142px;
  }

  .sponsor-bottom-image {
    position: relative;
    top: -75px;
    right: -190px;
    width: 142px;
  }
}

@media (max-width: 575.98px) {
  .d-xs-none {
    display: none !important;
  }

  .min-vh-100,
  .landing-hero-section {
    min-height: 50vh !important;
  }

  .flex-xs-column {
    flex-direction: column !important;
  }

  .px-sm-1 {
    padding: 0 10px !important;
  }

  .featured-jobs-sm {
    padding: 10px !important;
    min-height: fit-content;
  }

  .featured-job {
    width: 100%;
    height: fit-content;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .my-sm-3 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .w-xs-100 {
    width: 100% !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  #landing-talent-section:before {
    height: calc(100% - 120px);
  }

  .sponsor-top-image {
    position: relative;
    bottom: -108px;
    left: -50px;
    width: 142px;
  }

  .sponsor-bottom-image {
    position: relative;
    top: -75px;
    right: -190px;
    width: 142px;
  }

  .signup-options {
    flex-direction: column !important;
  }

  .signup-option {
    width: 80%;
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
