/*body {*/
/*  margin: 0;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

::-webkit-scrollbar {
  width: 5px;
  background: lightgray;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: darkgray;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hideInputActiveState:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.hideInputActiveState:focus-visible {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

#emailMessageField:focus {
  border: none !important;
  box-shadow: none !important;
}
#emailMessageField:active {
  border: none !important;
}
