// Here you can add other styles
:root {
  --primary: #1FAB89;
  --primary-dark: #014337;
  // --white: #ffffff;
}

body {
  font-family: 'DM Sans', sans-serif;
}

.worknation-logo {
  width: 13rem;
  height: 7rem;
}

.delete-user {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2rem 2rem 2rem;
  border: none;
  background-color: #4681c4;
  color: white;
  opacity: 0.8;
  border-radius: 5px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.delete-user:hover {
  background-color: #85a2c3;
}

// .delete-icon {
//   margin-top: 40rem;
//   margin-left: 5rem;
// }

ul {
  margin: 0;
}

.gradient-btn {
  background: var(--cui-gradient) !important;
}

.normal-btn {
  background: #01aeef;
}

.landing-bg {
  background-color: #f1f5ff;
  height: 100vh;
}

.landing-container {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.container-header h1 {
  font-weight: 700;
}

.go-up {
  color: #01aeef;
  height: 50px;
  width: 50px;
}

.custom-btn {
  width: 200px;
  height: 50px;
  background-color: #1FAB89;
}

.custom-btn:hover {
  background-color: #1FAB89;
}

.custom-btn:disabled {
  background-color: #adadad;
}

.outlined-btn {
  background-color: transparent;
  border-color: black;
}

.outlined-btn:hover {
  border-color: black;
}

.hov:hover {
  cursor: pointer;
}

.play-video {
  background-color: transparent;
  color: #01aeef;
  box-shadow: none !important;
  border: none !important;
  text-decoration: none;
  margin: 0 1rem;
}

.play-video:hover {
  background-color: transparent;
  color: #01aeef;
}

.container-header p {
  color: #232c41;
}

.landing-nav {
  background-color: transparent;
  box-shadow: none;
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.play-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2rem;
  width: 100%;
}

.play {
  height: 3rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
}
.play p {
  margin-top: 1rem;
}

.login {
  margin-left: 3rem;
  margin-top: 0;
}

.mb-icon {
  display: none;
}

.div-text {
  display: block;
  align-items: center;
  justify-content: center;
}

.page-space {
  padding-top: 6rem;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-r-12 {
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
}
.border-t-5 {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.border-b-5 {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.border-r-5 {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.border-l-5 {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.border-l-12 {
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
}

.footer-a {
  text-decoration: none;
  color: inherit;
}

.bg-color {
  background: #eff4ff;
}

.w-50 {
  width: 50% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-80 {
  width: 80% !important;
}
.w-90 {
  width: 90% !important;
}

.mt-10 {
  margin-bottom: 6rem !important;
}

.px-10 {
  padding: 0 5rem;
}

.vh-full {
  height: calc(100vh - 200px) !important;
}

.w-cv {
  min-width: 600px;
}
.main-title {
  font-size: 20px;
}
.bg-green-temp {
  background: #045F2D;
}
.bg-green-tinted {
  background: #014337;
}
.login-form-check-label > label {
  color: #4b4b4b;
  opacity: 0.5;
}

.pointer:hover {
  cursor: pointer;
}

.header-dropdown {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 4px 5px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 60%;
  left: 40%;
}

.header-dropdown li {
  padding: 1rem 0;
  margin-left: -2rem;
  border-bottom: 2px solid rgb(212, 211, 211);
}

.back-one {
  z-index: 0;
}

.back-neg {
  z-index: -1;
}
.bd-color-black {
  background: #f1f1f1;
  border: 2px solid #8e8e8e;
}
.front-one {
  z-index: 1;
}

.top-12 {
  top: 30px;
}

.p-top {
  margin-top: -0.5rem;
}

.left-0 {
  left: 0;
}
.highest-z-index {
  z-index: 100000000;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.box-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
}

.bg-deem-light {
  background: #f8f8f8;
}

.w-23 {
  width: 23% !important;
}

.h-card {
  max-height: 450px;
}

.center-item {
  display: flex;
  align-items: center;
}

.hero-full {
  height: calc(100vh - 50px) !important;
}

.pt-10 {
  padding-top: 10%;
}

.pt-30 {
  padding: 20% 0;
}

.pt-15 {
  padding: 15% 0;
}
.section-spacing {
  padding-top: 4rem;
}
.grid-div {
  border: 2px solid #787878;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  height: 200px;
  position: relative;
}

.grid-div p {
  position: absolute;
  bottom: 4%;
}

.text-width {
  width: 50%;
}

.partners-section {
  border-radius: 8px;
  width: 100%;
  padding: 2rem;
}

.home-abt h5 {
  color: #01aeef;
}
.img-size {
  width: 80%;
}

.grid-position {
  bottom: 10%;
  left: 40%;
}
.footer-style {
  background-color: #eff4ff;
  color: white;
}

.footer-font {
  font-size: 14px;
}
.job-btn {
  background: #407bff;
  color: white;
  padding: 0.8rem 1rem;
  width: 50%;
  position: absolute;
  bottom: -13%;
  left: 10%;
}

.logo-size {
  height: 100%;
  width: 100%;
}

.logo {
  height: 50px;
}

.testimony-card {
  height: 50%;
  width: 80%;
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #eff4ff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 4px 5px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.testimony-card div {
  height: 10%;
  width: 10%;
}

.quote-size {
  width: 20px;
  height: 50px;
}

.mb-ft {
  display: none !important;
}

.ld-ft {
  display: block;
}

.contributor-size {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
}

.course-card {
  background: #ffffff;
  border-radius: 6px;
  padding: 0.5rem;
}

.course-card:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.landing-courseCard-div {
  margin: 0 2rem;
  text-align: center;
  color: #0844b8;
}

.landing-courseCard {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10rem;
  padding: 5rem;
  position: relative;
  z-index: -2;
}

.tag {
  background-color: #00a469;
  color: white;
  padding: 0.5rem;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  font-size: 10px;
  border-radius: 5px;
}

.next-steps {
  background-color: #f1f5ff;
  padding-bottom: 5rem;
}
.mb-nextSteps {
  display: none;
}

// .mb-nextSteps {
//   display: none;
// }

.sect-one {
  margin: 8rem 0;
}

.sect-card {
  background: #f5f8ff;
  box-shadow: 0 1.33081px 2.66162px rgba(0, 0, 0, 0.14), 0 2.66162px 3.32702px rgba(0, 0, 0, 0.12);
  border-radius: 20px 20px 20px 0;
  height: 5rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.mouse-svg {
  width: 1%;
  height: 1%;
}

.landing-grid-one {
  background-image: url('../images/employers.png');
  height: 20rem;
}

.landing-grid-two {
  background-image: url('../images/partners.png');
  height: 20rem;
}

.stat-container {
  background-color: #eff4ff;
  position: relative;
  border-radius: 12px;
  padding: 3rem 4rem 0 4rem;
  left: 0;
  z-index: -2px;
  height: 7rem;
  bottom: 10%;
}

.stat {
  height: 8rem;
  background-color: #6695ff;
  border-radius: 8px;
  color: white;
  // margin: 0 4rem
  position: absolute;
  z-index: 25;
  width: 90%;
}

.stat div {
  padding-left: 0.5rem;
}

.stat h3 {
  margin-top: 1rem;
}

.stat p {
  margin-top: -0.6rem;
}

.course-img {
  background-image: url('../images/courses-banner.png');
}

select:invalid {
  color: var(--cui-gray);
}

.bg-light-blue {
  background: #eff4ff;
}

.text-primary-alert {
  color: #00a469;
}
.employer-doc {
  max-height: 400px;
  max-width: 400px;
}

.bg-primary-light {
  background-color: #00a469;
}

.bg-table {
  background: rgba(0, 0, 0, 0.05);
}

.text-highlight {
  color: #00a469;
}

.resend-group:hover {
  cursor: pointer;
}

// about us style
.about-form {
  background: #ffffff;
  border: 1px solid #f6f6f6;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 2rem;
  width: 100%;
}

.about-intro {
  background: #00a469;
  padding: 2rem 6rem;
  // margin: 5rem 0;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  position: absolute;
  width: 60%;
  opacity: 0.7;
  left: 0;
  z-index: 2;
}

.about-img {
  position: relative;
  z-index: -1;
  left: 55%;
  right: 0;
  background-image: url('../images/about-img.png');
  background-repeat: no-repeat;
  height: 34rem;
  width: 42rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-btn {
  border: none;
  color: white;
  background: var(--cui-gradient) !important;
  border-radius: 5px;
}

.mb-grid {
  display: none;
}
.ld-grid {
  display: block;
}
.abt-input {
  padding: 0 10rem;
}

.sidebar {
  background-color: var(--primary);
}

.sidebar-brand-full {
  height: 80%;
  width: 80%;
}

.dashboard-bg {
  background-color: #f5f5f5;
}

.dashboard-header {
  background-color: white;
}

.dashboard-input {
  background-color: #f6f6f6;
  border: none;
}

.app-dropdown {
  background-color: transparent !important;
  color: black !important;
  box-shadow: none !important;
}

.dropdown-menu {
  padding: 0.5rem 2rem;
}

.dropdown-item {
  padding: 10px 0;
  background: transparent !important;
  //border-bottom: 2px solid rgb(178, 178, 178) !important;
  cursor: pointer !important;
}

.intro-card {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;
  background-color: white;
}

.intro-card h5 {
  color: #00a469;
  font-size: 20px;
}

.intro-card p {
  color: #707070;
  line-height: 17px;
}

.course-card {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background-color: #ffffff;
}

.course-card span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-card a {
  padding-right: 3rem;
  text-decoration: none;
  color: #00a469;
}

.course-card img {
  width: 100%;
}

.course-card h5 {
  width: 80%;
  font-size: 16px;
  line-height: 19.36px;
  margin-top: -0.8rem;
}

.course-card h6 {
  color: #4b4b4b;
  font-size: 14px;
}

.recommended-grid {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: white;
  height: 55vh;
}

.recommended-grid span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
}

.recommended-grid h5 {
  font-size: 14px;
}

.recommended-grid h6 {
  font-size: 12px;
}

.recommended-grid a {
  font-size: 14px;
}

.recommended-card {
  margin: 0.5rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  font-size: 12px;
}

.recommended-card img {
  width: 100%;
}

.recommended-card a {
  text-decoration: none;
}

.header-card {
  background: #ffffff;
  box-shadow: 0 2.02347px 6.0704px rgba(0, 0, 0, 0.1);
  border-radius: 8.09386px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;
}

.header-card article {
  line-height: 0.6;
}

.profile-grid {
  background: #f8f8f8;
}

.email-width {
  width: 100px;
}

.dashboard-avatar {
  width: 20%;
  height: 20%;
}

.profile-grid div {
  line-height: 0.4;
}

.search-div {
  background-color: #f6f6f6;
}

.search-input {
  width: 80%;
  background-color: #f6f6f6;
  margin-left: -2.5rem;
}

.icons-full {
  width: 15%;
  height: 15%;
}

.arrow-icon {
  width: 50px;
  height: 50px;
}

.truncate {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-icons {
  width: 10%;
  height: 10%;
  margin-right: 0.5rem;
  color: white;
}

.course-type-card {
  background: #ffffff;
  box-shadow: 0 1.73148px 5.19444px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  height: 400px;
  position: relative;
}

.course-type-card h4 {
  font-size: 20px;
  text-align: center;
  margin-bottom: -2rem;
}

.course-type-card img {
  width: 70%;
  height: 70%;
}

.course-type-card div {
  position: absolute;
  bottom: 8%;
  width: 100%;
  padding: 0 2rem;
}

.course-type-card button {
  padding: 0.5rem 2rem;
  width: 100%;
  background: linear-gradient(282.66deg, #31aef4 -33.09%, #00a469 67.8%);
  cursor: pointer;
}

.course-type-card p {
  padding: 0.5rem 2rem;
  width: 100%;
  color: #00a469;
  position: absolute;
  bottom: 4%;
  font-weight: 600;
  text-align: center;
}

.popular-card {
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.popular-card article {
  display: flex;
  justify-content: space-between;
}

.sub-card {
  background: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
  margin: 0.5rem 0;
}

.sub-card p {
  color: #00a469;
}

.courses-nav li {
  margin: 0 1rem;
}

.program-header {
  background: linear-gradient(282.66deg, #31aef4 -33.09%, #00a469 67.8%);
  padding: 4rem 0;
  border-radius: 8px;
  line-height: 0;
  margin: 2rem 0;
}

.program-header h3 {
  margin-top: -0.5rem;
}

.program-card {
  background: #ffffff;
  box-shadow: 0 1.73148px 5.19444px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem 0;
}

.program-card hr {
  height: 3px;
  width: 80%;
  background-color: #afafaf;
}

.program-card h5 {
  color: #4b4b4b;
  font-weight: 800;
}

.program-card h6 {
  font-size: 14px;
  padding-bottom: 0.4rem;
  padding-left: 0.2rem;
}

.program-card article {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 2;
}

.program-dates article {
  padding: 0.5rem 0;
}

.program-card button {
  padding: 0.5rem 5rem;
  background: linear-gradient(282.66deg, #00a469 -33.09%, #00a469 67.8%);
}

.benefit-section {
  padding: 1rem 0;
  border: 1px solid #afafaf;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem 0;
  border-radius: 5px;
}

.benefit-section ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.benefit-section li {
  list-style-type: disc;
  margin: 0.2rem 2rem;
}

.program-table {
  border: 2px solid rgba(0, 0, 0, 0.05);
}

.program-table th {
  background: rgba(0, 0, 0, 0.05);
  border-left: 2px solid rgba(0, 0, 0, 0.05);
  width: 20%;
  text-align: center;
  color: #1b1b1b;
  font-size: 12px;
}

.program-table td {
  width: 20%;
  font-size: 14px;
  border-left: 2px solid rgba(0, 0, 0, 0.05);
}

// Admin Styles

.admin-header-card {
  background: #ffffff;
  box-shadow: 0 2.02347px 6.0704px rgba(0, 0, 0, 0.1);
  border-radius: 8.09386px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem;
}

.admin-header-card img {
  width: 30%;
  height: 30%;
}

.admin-header-card article {
  margin-left: 1rem;
}

.per {
  color: #3dec00;
  padding-right: 0.3rem;
}

.neg-per {
  color: #ff0000;
  padding-right: 0.3rem;
}

.tab-one,
.tab-two,
.tab-three,
.tab-four,
.tab-five,
.tab-six {
  border-radius: 16px;
  margin: 0 1rem;
  cursor: pointer;
  font-size: 12px;
  padding: 0.5rem 0;
}

.tab-one {
  border: 1px solid blue;
  background-color: blue;
  color: white;
}

.tab-two {
  border: 1px solid #002985;
  color: #002985;
}

.tab-three {
  border: 1px solid #009f49;
  color: #009f49;
}

.tab-four {
  border: 1px solid #cb00b7;
  color: #cb00b7;
}

.tab-five {
  border: 1px solid #ff9900;
  color: #ff9900;
}

.tab-six {
  border: 1px solid #007686;
  color: #007686;
}

.btn-icons {
  height: 20%;
  width: 20%;
}

.export-btn {
  background: linear-gradient(282.66deg, #31aef4 -33.09%, #0076e3 67.8%);
  color: white;
  padding: 0.8rem 1rem;
  border: none;
  margin: 0 0.5rem;
  border-radius: 4.3287px;
}

.sort-btn {
  padding: 0.8rem 1rem;
  margin: 0 0.5rem;
  border-radius: 4.3287px;
  border: 1px solid #b5b4b4;
  background: white;
}

.paid-btn {
  border: 1px solid #009f49;
  color: #009f49;
  background: #e6ffe5;
  border-radius: 12.5px;
  font-size: 12px;
  padding: 0 0.5rem;
  width: 80%;
}

.app-table th {
  background-color: #002e64;
  color: white;
}

.app-table td,
.app-table th {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: none;
}

.app-table tr {
  background-color: white;
}

.app-table tr:nth-child(even) {
  background-color: #f6f6f6;
}

.clickable-icon:hover {
  cursor: pointer;
}

.btn-check:checked + .btn, .btn-check:hover + .btn {
  color: #FFFFFF;
}

.text-primary-dark {
  color: var(--primary-dark);
}

.bg-primary-dark {
  background-color: var(--primary-dark);
}

.scrollable-div {
  width: 100px;
  overflow-y: scroll;
}

.scrollable-div::-webkit-scrollbar {
  width: 5px;
}

.scrollable-div::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background: #00473E; /* Dark green color */
  border-radius: 10px;
}

.scrollable-div::-webkit-scrollbar-thumb:hover {
  background: #00322A; /* Even darker green when hovered */
}


.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}

.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 300ms, opacity 300ms;
}

.slide-exit {
  transform: translateX(0);
  opacity: 1;
}

.slide-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: transform 300ms, opacity 300ms;
}
.text-italic {
  font-style: italic;
}

.orangeColor {
  color: #ff5656;
}
.bgOrangeColor {
  background-color: #ff5656;
}

.greyColor {
  color: #575656;
}

.bg-grey100 {
  background: #fbfbfb;
}
.gray200 {
  color: #a1a1a1;
}

.darkGreenText {
  color: #014337;
}

.lightGrey {
  color: #909090;
}

.bgGreyColor {
  color: #d5efe3;
}
