@media (max-width: 768px) {
  .mb-container {
    width: 90%;
    margin: auto;
    // background-color: #f1f5ff;
  }

  .w-40 {
    width: 100% !important;
  }
  .w-90 {
    width: 100%;
  }
  .w-90 {
    width: 100%;
  }
  .mb-hidden{
    display: none;
    visibility: hidden;
  }
  .mb-show{
    visibility: visible;
  }

  .w-60 {
    width: 100% !important;
  }
  .job-detail-img{
    min-height: 40px;
  }
  .mb-nextSteps img {
    width: 100%;
  }
  .mb-nextSteps {
    display: block;
  }
  .mb-nextSteps p {
    width: 100%;
  }
  .next-steps {
    display: none;
  }
  .logo-size {
    width: 30%;
  }
  .landing-container {
    text-align: center;
  }
  .landing-container img {
    width: 100%;
  }
  .landing-container h1 {
    font-size: 40px;
    color: #002985;
  }
  // .landing-nav {
  //   display: none;
  // }
  .mb-icon {
    display: block !important;
    position: absolute;
    font-size: 30px;
    color: black;
    top: 15px;
    right: 25px;
  }
  .nav-links {
    display: none !important;
  }
  .mb-nav-links {
    position: absolute;
    display: block;
    background-color: #f1f5ff;
    left: 0;
    top: 75px;
    width: 100%;
    height: 100vh;
    transition: all 0.5s ease-out;
  }
  .mb-nav-links ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 5rem;
  }
  .mb-nav-links li {
    margin: 1rem 0;
    margin-right: 3rem;
  }

  .mb-union-img {
    width: 100%;
  }

  .partners-section {
    width: 80%;
  }

  .partners-img {
    width: 30%;
  }

  .text-width {
    width: 100%;
  }

  .grid-div {
    padding: 1rem 1.5rem 1rem 0.5rem;
    height: 270px;
  }

  .grid-div p {
    position: absolute;
    bottom: 3%;
  }

  .mb-grid-space {
    margin-bottom: 1.5rem;
  }

  .stat-container {
    display: none;
  }
  .landing-courseCard-div {
    margin: 0;
  }
  .sect-one {
    margin-top: -4rem;
  }
  .choose-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .choose-img img {
    width: 100%;
  }
  .testimony-card {
    padding: 1rem;
  }

  .testimony-card div {
    height: 20%;
    width: 20%;
  }

  .footer-contact {
    display: flex;
    justify-content: flex-start !important;
  }
  .footer-col {
    margin-top: 1.5rem;
  }
  .grid-position {
    bottom: 10%;
    left: 33%;
  }
  .landing-grid-one p {
    width: 100%;
  }

  .landing-grid-two p {
    width: 100%;
  }
  .testimony-card {
    width: 100%;
  }
  .ft-grid {
    float: none !important;
  }
  .ft-grid img {
    width: 60% !important;
  }
  .video {
    height: 300px;
    margin-bottom: -11rem;
  }
  .ld-ft {
    display: none;
  }
  .mb-ft {
    display: block !important;
    position: relative;
    z-index: -3px;
  }
  .mb-ft img {
    width: 100%;
  }
  .job-btn {
    position: absolute;
    z-index: 5;
    width: 80%;
    left: 0;
  }
  .job-btn div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .job-btn p {
    padding-top: 2rem;
  }
  .abt-hero {
    display: flex;
    flex-direction: column;
  }
  .mb-grid {
    display: block;
  }
  .ld-grid {
    display: none;
  }
  .abt-input {
    padding: 0;
  }
  .abt-hero {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
  }

  .mb-about-intro {
    background: white;
    padding: 2rem 0;
  }
  .about-img {
    z-index: -1;
    left: 0;
    right: 0;
    background-image: url('../images/about-img.png');
    background-repeat: no-repeat;
    height: 34rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img-ill {
    margin-top: 5rem;
  }
  .login {
    display: flex;
    justify-content: center;
    // margin-top: 2rem;
    margin-left: 0;
  }
  .play-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 100%;
  }

  .play img {
    width: 30%;
  }
}
