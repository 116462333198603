// If you want to override variables do it here
@import 'variables';

$enable-ltr: true; /* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true; /* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import '../../../node_modules/@coreui/coreui-pro/scss/coreui';
@import '../../../node_modules/@coreui/coreui-pro/scss/themes/dark';

// Import Chart.js custom tooltips styles
@import '../../../node_modules/@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';

// Plugins
@import 'calendar';

// Prism.js
@import 'examples';

// If you want to add custom CSS you can put it here.
@import 'custom';

// custom mobile styling.
@import 'mobile';

// workNation styling.
@import 'wnDesktop';

// New Custom Css to Replace the Top
@import 'wnNewCustom';
@import 'cv-builder/verticalTemplate';
@import 'cv-builder/selectCVCreationOption';

.smallText {
  font-size: 12px !important;

  > ::placeholder {
    font-size: 8px;
  }
}
