:root {
  --primary: #1fab89;
  --hov: #83d0ab;
  --cui-input-box-shadow: #1fab89;
  $input-focus-box-shadow: #1fab89;
  // --white: #ffffff;
}

.nav-header {
  padding: 1rem 0;
}
.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
  content: '\25B2';
  position: absolute;
  top: 3%;
  left: 45%;
  margin: auto;
}

.react-datepicker__year-dropdown::after {
  content: '\25BC';
  position: absolute;
  left: 45%;
  bottom: 3%;
}
.login-btn {
  color: var(--primary) !important;
  font-weight: 700 !important;
}

.reg-btn {
  background-color: var(--primary) !important;
  color: white;
  padding: 0.5rem 1rem;
  font-weight: 700 !important;
}
.scrollable-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
}

.otp-btn {
  background-color: var(--primary) !important;
  color: white;
  font-weight: 700;
}

.reg-btn:hover {
  background-color: var(--hov) !important;
}
.btn-outline-success:hover {
  color: white;
}
.btn-active-color {
  background-color: white;
}
.btn-active-color {
  color: white;
}
.sh-btn {
  padding: 1rem 2rem;
  font-weight: 700;
}
.sh-btn:hover {
  background-color: var(--hov) !important;
  font-weight: 700;
}

// footer style
.footer {
  background-color: #edfff6;
  padding: 3rem 0 0;
}

.footer h6 {
  font-weight: 600;
}

.footer-card h6 {
  text-align: center;
}

.footer-card {
  background: rgba(255, 255, 255, 0.5);
  opacity: 0.8;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  border-radius: 7px;
  padding: 2rem 1rem;
}

.footer-card h5 {
  font-size: 14px;
}

.footer-card p {
  font-size: 12px;
}

.footer-l {
  text-decoration: none !important;
  color: rgb(107, 133, 128) !important;
  font-size: 14px;
}

.text-decoration-none {
  text-decoration: none;
}
.tooltip-text {
  min-width: 507px;
  left: 86px;
  top: 0;
  text-align: left;
  z-index: 1;
  visibility: hidden;
}
.tooltip-btn {
  max-width: 90px;
}

.cv-progress {
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
.cv-bar {
  height: 0;
  border: 1px solid;
  border-color: #c2c2c2;
}

.tooltip-btn:hover .tooltip-text {
  visibility: visible;
}

.enroll-btn {
  background: linear-gradient(
    90.06deg,
    rgba(43, 52, 99, 0.8) 38.55%,
    rgba(7, 134, 73, 0.4) 124.61%
  );
  border-radius: 6px;
}

.cv-btn {
  width: 190px;
}

.cv-btn-save {
  width: 250px;
}

.footer-bottom {
  display: flex;
  justify-content: center;
  opacity: 50;
}

.shadow-sm-user {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
// hero style
.landing-hero {
  height: 90vh;
}

.main {
  margin-top: 75px;
}

.soc-div {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.soc-div div {
  padding: 1rem 0;
}

.icons-div {
  position: absolute;
  top: 15%;
}

.scroll {
  background: var(--primary);
  width: 100%;
  height: 10rem;
  border-radius: 0 !important;
  position: absolute;
  bottom: 0;
}

.scroll:hover {
  background: var(--hov);
}

.text-justify {
  text-align: justify;
}

.hero-div {
  width: 90%;
  background-image: url('../images/hero-img.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.talent-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 15px;

  h5 {
    font-weight: bold;
  }

  img {
    height: 104px;
    width: auto;
  }
}

.talent-card p {
  width: 90%;
}

.feat-jobs {
  background-color: var(--primary);
  height: fit-content;
}

.feat-div {
  background-color: white;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emp-section {
  width: 100%;
}

.emp-btn {
  background: linear-gradient(
    240.76deg,
    rgba(255, 255, 255, 0.25) 20.84%,
    rgba(133, 240, 189, 0.335) 137.41%
  );
  box-shadow: 0 2px 9px rgba(36, 36, 36, 0.2);
  backdrop-filter: blur(25px);
  border-radius: 5px;
  color: black;
  font-weight: 600;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.emp-btn:hover {
  background: linear-gradient(
    240.76deg,
    rgba(255, 255, 255, 0.25) 20.84%,
    rgba(133, 240, 189, 0.335) 137.41%
  ) !important;
}

.abt-grid {
  padding: 1rem;
  background-color: white;
  border: 2px solid #bababa;
  border-radius: 5px;
  width: fit-content;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.inputBorder {
  border: 1px solid #bababa;
  border-radius: 4px;
}
.inputBorder::placeholder {
  font-size: 0.8rem;
  color: #575656;
  opacity: 0.5;
}
.inputBorder option,
select:active {
  font-size: 0.8rem;
  color: #575656;
  opacity: 0.5;
}

.labelColor {
  color: #3f3b3b;
}

.contact-us {
  background-color: transparent !important;
  color: var(--primary) !important;
  box-shadow: none !important;
  border: 1px solid var(--primary) !important;
  padding-left: 3rem;
  padding-right: 3rem;
}

.emp-grid {
  text-align: center;
  padding: 2.5rem;
}

.emp-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emp-container img {
  width: 40%;
  height: 40%;
}

.emp-card-one {
  max-width: 400px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.emp-card-one h1 {
  color: var(--primary);
}

.emp-card-two,
.emp-card-three {
  background: #ffffff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: flex;
  padding: 15px;
  max-width: 400px;
  height: 35%;

  h5 {
    font-weight: bold;
  }

  img {
    width: 104px;
    height: auto;
  }
}

.emp-card p {
  font-size: 14px;
}

.font-color {
  color: var(--primary);
}

.font-color a {
  color: var(--primary);
}

.text-title {
  color: var(--cui-black);
  font-size: 1.25rem;
  text-align: center;
  letter-spacing: 3px;
}

.close {
  font-size: large;
  float: right;
}

.scores {
  color: var(--primary);
  font-size: 1.5rem;
  text-align: center;
  font-weight: bolder;
}

.registration-bg {
  background-image: url('../images/sign-up-image.svg');
  background-position: center;
  background-size: contain;
  justify-content: center;
}

.landing-page-social-icon {
  width: 25px;
  height: 25px;
}

.entry-form-main-div {
  .form-image {
    width: 90%;
    height: fit-content;
  }

  .logo {
    width: 30%;
    height: fit-content;
  }
}

.navbar-toggler {
  border-color: var(--primary);
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0,197,102)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.active-bar {
  background-color: #e9fdf3;
  border-left: black solid 2px;
  border-bottom: black solid 2px;
  border-radius: 10px 0 0 10px;
}
.drop-down-link {
  color: grey;
  text-decoration: none;
}

.drop-down-link:hover {
  color: black;
  text-decoration: none;
}
.job-detail-img {
  min-height: 200px;
}

.tag {
  background-color: #00381d;
  color: white;
  padding: 0.5rem;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  font-size: 12px;
  font-weight: bold;
}
.mt-n1 {
  margin-top: -0.25rem !important;
}
.badge-div {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 5em;
  width: 5em;
  clip-path: polygon(0 0, 100% 0, 100% 0%, 0 100%);
}

.paging {
  color: white;
  font-size: 12px;
  font-weight: bold;
}
.paging:hover {
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.contact-us {
  margin: 0 20%;
  width: 60%;
}

.partners-img {
  width: 8rem;
}

.form-label {
  font-size: small;
}

.w-70 {
  width: 70%;
}

.w-100 {
  width: 100%;
}

.st-card {
  max-width: 700px;
  padding-left: 25px;
  padding-right: 25px;
}

.close {
  background-color: transparent;
  padding: 0;
  border: 0;
  font-weight: 400;
  font-size: 35px;
  width: 40px;
  height: 65px;
  align-self: flex-end;
  margin-top: -20px;
  margin-right: 5px;
}

.custom-modal {
  position: relative;
}

.label {
  font-weight: 500;
}

.update-profile-form {
  .label {
    font-weight: 500;
  }

  input,
  textarea,
  select,
  .form-check-label {
    font-weight: 300;
  }

  input,
  textarea,
  select,
  .form-multi-select {
    background: white;
    border-radius: 0;
  }

  .form-multi-select-options {
    height: 70vh;
    overflow-y: scroll;
  }

  .form-multi-select-search {
    width: 100%;
  }
}

.section-card {
  background-color: #fcfcfc;
  padding: 20px 20px 30px;
  box-shadow: 0 2px 6px 0 rgba(53, 53, 53, 0.2);
}

.TPFInage {
  width: 17px;
  height: 17px;
}
.p-card-div {
  position: relative;
}
.p-logo {
  width: 40%;
  height: 40%;
  margin-top: -50px;
  margin-right: 0;
}

.job-logo {
  width: inherit;
  height: auto;
}
.job-title {
  line-height: 22px;
  font-size: 14px;
}
.job-detail-body {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: gray;
  text-align: justify;
}
.job-detail-summary {
  background: #eefff5;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.dt-hidden {
  visibility: hidden;
}
.dt-show {
  visibility: visible;
}

.chop {
  width: 12px;
  height: 12px;
}
.pt-btn {
  background: #dadada;
  border-radius: 4px;
  font-size: 14px;
  line-height: 17px;
  max-height: 28px;
}

.job-tab-div {
  background-color: #c4c4c4ff;
  border-radius: 6px;
  max-width: 360px;
}

.job-detail-div {
  padding: 20px;
}
.bg-light-green {
  background: #f9fffb;
}

.bg-light-green-2 {
  background: #effff2;
}

.btn-interview-btn {
}

.bg-grey {
  background-color: #e7e7e7;
}

.bg-grey-700 {
  background-color: #f5f5f5;
}

.bg-grey-resume {
  background-color: #f3f3f3;
}

.card-footer {
  background-color: #f0f0f0;
}
.footer-div {
  position: absolute;
  bottom: 0;
}
.mt-15 {
  padding-top: 13%;
}
.date-div {
  position: absolute;
  bottom: 55px;
}

.date-div-2 {
  position: absolute;
  bottom: 30px;
}

.img-h-25 {
  top: 0;
  z-index: 1;
  min-height: 150px;
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}

.image-fill {
  object-fit: fill;
}
.image-contain {
  object-fit: contain;
}
.image-cover {
  object-fit: cover;
}
.image-scale-down {
  object-fit: scale-down;
}
.image-none {
  object-fit: none;
}
.figma-1 {
  top: -6px;
  left: -6px;
  width: 10px;
  height: 10px;
}
.figma-2 {
  top: -6px;
  right: -6px;
  width: 10px;
  height: 10px;
}
.figma-3 {
  right: -6px;
  bottom: -6px;
  width: 10px;
  height: 10px;
}
.figma-4 {
  left: -6px;
  bottom: -6px;
  width: 10px;
  height: 10px;
}
.figma-5 {
  right: -12px;
  bottom: 0;
  width: 10px;
  height: 10px;
}

.left-blur {
  position: absolute;
  width: 142px;
  height: 142px;
  left: 0;
  top: 50px;
  background: linear-gradient(180deg, #2cc67c 0%, rgba(255, 182, 41, 0.88) 100%);
  filter: blur(99px);
}
.right-blur {
  position: absolute;
  width: 142px;
  height: 142px;
  right: 0;
  top: 100px;
  background: #2cc67c;
  filter: blur(99px);
}

.o-bootcamp {
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #00ab59;
}
.sub-body {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #919191;
  padding-top: 2px;
  line-height: 14px;
}

.job-body {
  font-weight: 400;
  color: #919191;
  font-size: 14px;
  line-height: 17px;
}

.job-bookmark {
  width: 20px;
  height: 40px;
}

.job-tag {
  font-weight: 400;
  font-size: 14px;
  color: #454545ff;
  padding-top: 5px;
  padding-bottom: 5px;
}

.t-card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  padding-bottom: 0;
  color: #2d2d2d;
}

thead > tr:first-child {
  background-color: var(--primary);
  color: white;
}

tr {
  line-height: 3;

  th:nth-of-type(5) {
    width: 20%;
  }

  th:nth-of-type(6) {
    width: 10%;
  }

  .form-check-input[type='checkbox'] {
    vertical-align: text-top;
    font-size: 1rem;
  }

  span {
    display: block;
    padding: 5px 0 0;
    line-height: 1;
  }

  .user-name {
    line-height: 1;
    padding-bottom: 5px;
  }

  .user-status-active {
    font-size: 11px;
    background-color: lightgreen;
    padding: 2px 4px;
    border-radius: 3px;
  }

  .user-status-inactive {
    font-size: 11px;
    background-color: darksalmon;
    color: red;
    padding: 2px 4px;
    border-radius: 3px;
  }
}

th,
td {
  font-size: 0.85rem;

  span {
    width: max-content;
  }
}

.users-action-buttons {
  font-size: 0.85rem;
  margin: 0 5px;
}

.reports-table {
  tr,
  td {
    font-size: 0.75rem;
  }
}

.bg-blue {
  background-color: #4e4feb;
}
.bg-blue-400 {
  background-color: #4e4feb;
}
.bg-blue:hover {
  background-color: #4e4feb;
}
.bg-blue-400:hover {
  background-color: #2b2cdc;
}
.bg-green-100 {
  background: #97e5bb;
}
.border-green-100 {
  border-color: #97e5bb;
}
.user-dashboard-banner {
  background-image: linear-gradient(to right, #2a3362, #236152);
  border-radius: 5px;

  img {
    width: 88px;
    height: 88px;
  }

  .tier {
    border: 1px white solid;
    border-radius: 8px;
    min-width: 50px;

    img {
      width: 24px;
      height: 24px;
    }
  }
}
.sparkles {
  right: 12px;
  top: -25px;
  z-index: 2;
}

.btn-tap:hover {
  background-color: var(--primary) !important;
  color: white;
}
.user-tag {
  width: 120px;
  height: 70px;
}

.user-dashboard-stat-1 {
  border-left: 10px solid #005695 !important;
}
.user-dashboard-stat-2 {
  border-left: 10px solid var(--primary) !important;
}
.user-dashboard-stat-3 {
  border-left: 10px solid #eb8f00 !important;
}
.training-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.job-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.training-image-div {
  max-height: 170px;
  height: 100%;
  width: 100%;
}
.job-logo-div {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -35px;
}
.cv-builder-announcement {
  border-radius: 6px;
  background: #00a995;
  padding: 0 10px;
}

.c-shape {
  width: 84px;
  height: 150px;
  top: -62.48px;
  left: 13.79px;
  border: 1px;
  font-size: 128px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  transform: rotate(22.313deg);
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .abt-grid {
    font-size: 16px;
  }
  .talent-card article {
    padding-left: 15px;
  }
  .emp-card-two,
  .emp-card-three {
    img {
      width: 125px;
      height: 75px;
    }
  }

  .partners-section div {
    img {
      width: 25%;
      height: fit-content;
      margin: 0 15px;
    }
  }

  .custom-entry-form {
    width: 65%;
  }

  .sign-up-success {
    width: 70% !important;

    img {
      width: 94px;
    }
  }

  .testimony {
    display: block !important;
    width: 100vh;
    margin: 0 12vh;
  }

  .testimony-body {
    padding: 2rem !important;
  }
}

/* Smartphones (portrait) */
@media only screen and (max-width: 450px) {
  /* Styles */
  .hero-div {
    padding: 0 25px;
  }
  .st-card {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .abt-grid {
    width: 100%;
    height: 120px;
    font-size: 16px;
  }
  .emp-card-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .partners-section div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 40%;
      height: fit-content;
      margin: 15px 0;
    }
  }
  .text-sm-center {
    text-align: center;
  }
  .custom-entry-form {
    width: 100%;
  }
  .section-card {
    padding-left: 2px;
    padding-right: 2px;
  }

  .sign-up-success {
    width: 90% !important;
  }

  .update-profile-btn {
    width: 100% !important;
  }

  .talent-card {
    width: 95%;
  }

  .testimony {
    display: block !important;
    width: 85vh;
    margin: 0 15px;
  }

  .testimony-body {
    padding: 2rem !important;
  }

  .job-detail-summary {
    flex-direction: column;
  }
}

/* Smartphones - Smaller Screens (portrait) */
@media only screen and (max-width: 380px) {
  .testimony {
    display: block !important;
    width: 100vh;
    margin: 0 15px;
  }
}
