
.select-cv-card {
  border: 2px solid springgreen;
  border-radius: 1rem;
  height: 200px !important;
  width: 45%;
 cursor: pointer;

}

.select-cv-card {
  border-radius: 15px;
  margin: 10px;
  padding: 40px 30px;
  border: 2px solid springgreen;
  background: #FFFFFF;
  color: grey;
  cursor: pointer;

}

.select-cv-card-active, .select-cv-card:hover {
  background: var(--primary);
  color: #FFFFFF;
  border: 1px solid var(--primary);
}
