/*
Brown ScatteredGrayTemplate
*/
.rect-dash {
  width: 40.88px;
  height: 5.59px;
  left: -3.24px;
  top: 58.24px;
  background: #FF7C7C;
}

.rect-contact {
  width: 25.88px;
  left: -3.24px;
  top: 58.24px;
  background: #510505;
  color: #FFFFFF;
}

.bg-brown-temp {
  background: #510505;
  color: #FFFFFF;
}

.main-content {
  font-size: 12px;
  word-wrap: break-word;
}

.mr {
  margin-right: 20px;
}

.ml {
  margin-left: 20px;
}

.h-60 {
  height: 60% !important;

}

.main-title-cv {
  font-size:15px;
  color: #FF7C7C;
  word-wrap: break-word;
}

.sub-title-cv {
  font-size: 14px;
  font-weight: bolder;
}

